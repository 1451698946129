body {
    background: #fff;
    color: #666666;
    font-family: "Open Sans", sans-serif;
    overflow: auto !important
}

a {
    color: #15227e;
    transition: .5s;
    text-decoration: none
}

@media only screen and (min-width:768px) {

    a:hover,
    a:active,
    a:focus {
        color: #5ca595;
        outline: none;
        text-decoration: none
    }
}

p {
    padding: 0;
    margin: 0 0 30px 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin: 0 0 20px 0;
    padding: 0
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #f2f2f2;
    border-top: 6px solid #5ca595;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1s linear infinite
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

#header {
    transition: all .5s;
    z-index: 997;
    background: rgba(0, 0, 0, .9);
    height: 80px
}

#header.header-transparent {
    background: transparent
}

#header.header-scrolled {
    background: rgba(0, 0, 0, .9);
    height: 60px
}

#header .logo {
    font-size: 34px;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: 3px;
    padding-left: 10px;
    border-left: 4px solid #5ca595
}

#header .logo a {
    color: #fff
}

#header .logo img {
    max-height: 40px
}

@media (max-width:992px) {
    #header .logo {
        font-size: 28px
    }
}

.navbar {
    padding: 0
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none
}

.navbar li {
    position: relative
}

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 35px;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #15227e;
    white-space: nowrap;
    text-transform: uppercase;
    transition: .3s
}

@media only screen and (min-width:320px) and (max-width:768px) {

    .navbar a,
    .navbar a:focus {
        padding: 10px 0 0 5px
    }

    .btn-close:focus {
        box-shadow: none !important
    }

    .sticky_header {
        position: sticky !important;
        top: 0;
        z-index: 1020
    }
}

.navbar-toggler {
    font-size: 2rem !important
}

.navbar-toggler:focus {
    box-shadow: none !important
}

.btn-close {
    width: 4rem !important;
    color: black;
    background-color: bisque !important;
    padding: 15px 0 15px 0 !important;
    margin-bottom: 0 !important
}

.navbar a i,
.navbar a:focus i {
    font-size: 14px;
    line-height: 0;
    margin-left: 5px
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: #15227e
}

.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 34px;
    top: calc(100% + 30px);
    margin-top: 4px;
    padding: 5px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0 0 30px rgba(127, 137, 161, .25);
    transition: .3s
}

.navbar .dropdown ul li {
    min-width: 200px
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    text-transform: none;
    color: #666666
}

.navbar .dropdown ul a i {
    font-size: 12px
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
    color: #5ca595
}

.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden
}

.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible
}

@media (max-width:2560px) {
    .navbar .dropdown .dropdown ul {
        left: -90%
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -90%
    }
}

@media only screen and (min-width:320px) and (max-width:768px) {
    .navbar .dropdown .dropdown:hover>ul {
        left: 20%
    }
}

.mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: .5s
}

@media (max-width:991px) {
    .mobile-nav-toggle {
        display: block
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(26, 26, 26, .9);
    transition: .3s;
    z-index: 999
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: .3s
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #333333
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: #5ca595
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0 0 30px rgba(127, 137, 161, .25)
}

.navbar-mobile .dropdown ul li {
    min-width: 200px
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    color: #5ca595
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block
}

.navbar-nav .nav-link.active {
    color: #15227e !important
}

#carouselExampleDark {
    height: 84.5vh;
    width: 100%;
    background: #000;
    overflow: hidden;
    position: relative
}

#carouselExampleDark .carousel,
#carouselExampleDark .carousel-inner,
#carouselExampleDark .carousel-item,
#carouselExampleDark .carousel-item::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0
}

.carousel-item::before {
    content: "";
    background-color: rgba(92, 165, 149, .4)
}

.carousel-indicators button {
    color: white;
    background-color: none
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: white !important
}

.carousel-control-prev-icon {
    color: white
}

.carousel-dark .carousel-caption {
    color: white !important
}

.bg_img_1 {
    background-image: url('../public/assets/images/Home-page-img/hero-section/sacks-healthy-legumes-grains.webp')
}

.bg_img_2 {
    background-image: url('../public/assets/images/Home-page-img/hero-section/bio-fuel-image.webp') !important
}

.bg_img_3 {
    background-image: url('../public/assets/images/Home-page-img/hero-section/smooth-pebble-stack-wet.webp') !important
}

.bg_img_4 {
    background-image: url('../public/assets/images/Home-page-img/hero-section/plant-growing-soil-with-sun-shining-it.webp') !important
}

.bg_img_5 {
    background-image: url('../public/assets/images/Home-page-img/hero-section/group-steel-rod.webp') !important
}

.bg_style {
    height: 84.5vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

#carousel_title {
    position: absolute;
    right: 15%;
    bottom: 38%;
    left: 15%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 2.5rem;
    color: #fff;
    background-color: rgba(0, 0, 0, .3);
    text-align: center
}

#carousel_title h1 {
    font-size: 4rem;
    font-weight: 600;
    letter-spacing: .2rem;
    line-height: 3.5rem;
    padding: .7rem
}

@media only screen and (min-width:320px) and (max-width:768px) {
    #carousel_title h1 {
        font-weight: 600;
        font-size: 3rem
    }

    #carousel_title {
        bottom: 30% !important;
        font-size: 2rem
    }

    .sm_device_title {
        display: block !important
    }
}

#hero {
    width: 100%;
    height: 100vh;
    background: #000;
    overflow: hidden;
    position: relative
}

@media (max-height:500px) {
    #hero {
        height: 150vh
    }
}

#hero .carousel,
#hero .carousel-inner,
#hero .carousel-item,
#hero .carousel-item::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0
}

#hero .carousel-item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

#hero .carousel-item::before {
    content: "";
    background-color: rgba(0, 0, 0, .7)
}

#hero .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 70px;
    left: 50px;
    right: 50px
}

#hero .container {
    text-align: center
}

#hero h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 700
}

.carousel_description {
    font-size: 20px
}

@media (max-width:768px) {
    #hero h2 {
        font-size: 28px
    }
}

#hero p {
    width: 80%;
    margin: 0 auto 30px auto;
    color: #fff
}

@media (min-width:1024px) {
    #hero p {
        width: 60%
    }
}

#hero .carousel-fade {
    overflow: hidden
}

#hero .carousel-fade .carousel-inner .carousel-item {
    transition-property: opacity
}

#hero .carousel-fade .carousel-inner .carousel-item,
#hero .carousel-fade .carousel-inner .active.carousel-item-start,
#hero .carousel-fade .carousel-inner .active.carousel-item-end {
    opacity: 0
}

#hero .carousel-fade .carousel-inner .active,
#hero .carousel-fade .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-fade .carousel-inner .carousel-item-prev.carousel-item-end {
    opacity: 1;
    transition: .5s
}

#hero .carousel-fade .carousel-inner .carousel-item-next,
#hero .carousel-fade .carousel-inner .carousel-item-prev,
#hero .carousel-fade .carousel-inner .active.carousel-item-start,
#hero .carousel-fade .carousel-inner .active.carousel-item-end {
    left: 0;
    transform: translate3d(0, 0, 0)
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
    width: 10%
}

@media (min-width:1024px) {

    #hero .carousel-control-prev,
    #hero .carousel-control-next {
        width: 5%
    }
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
    background: none;
    font-size: 32px;
    line-height: 1
}

#hero .carousel-indicators li {
    cursor: pointer;
    list-style-type: none
}

#hero .btn-get-started {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 32px;
    border-radius: 50px;
    transition: .5s;
    margin: 10px;
    color: #fff;
    background: #5ca595
}

#hero .btn-get-started:hover {
    background: #fff;
    color: #5ca595
}

section {
    overflow: hidden
}

.section-header h2 {
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #5ca595;
    position: relative;
    z-index: 2;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative
}

.section-header h2:after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #5ca595;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto
}

@media (max-width:640px) {
    .section-header h2 {
        font-size: 28px;
        margin-bottom: 15px
    }
}

@media only screen and (min-width:768px) {
    .section-header h3 {
        font-size: 40px !important;
        padding-bottom: 15px !important;
        margin-bottom: 28px !important
    }
}

.section-header h3 {
    font-size: 32px;
    color: #5ca595;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    position: relative;
    padding-bottom: 15px
}

.section-header h3::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px)
}

.section-header h3::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #5ca595;
    bottom: 0;
    left: calc(50% - 20px)
}

.section-header p {
    text-align: justify;
    font-size: 18px;
    padding-bottom: 30px;
    color: #333
}

@media only screen and (min-width:320px) and (max-width:768px) {
    .section-header p {
        padding: 15px 10px
    }
}

.section-bg {
    background: #f7f7f7
}

.breadcrumbs {
    padding: 20px 0;
    background-color: #f7f7f7;
    min-height: 40px;
    margin-top: 80px
}

.breadcrumbs h2 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 400
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px
}

.breadcrumbs ol li+li {
    padding-left: 10px
}

.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/"
}

@media (max-width:768px) {
    .breadcrumbs .d-flex {
        display: block !important
    }

    .breadcrumbs ol {
        display: block
    }

    .breadcrumbs ol li {
        display: inline-block
    }
}

#featured-services {
    background: #000
}

#featured-services .box {
    padding: 30px 20px
}

#featured-services .box-bg {
    background-image: linear-gradient(0deg, #000000 0%, #242323 50%, #000000 100%)
}

#featured-services i {
    color: #5ca595;
    font-size: 48px;
    display: inline-block;
    line-height: 1
}

#featured-services h4 {
    font-weight: 600;
    margin: 15px 0;
    font-size: 20px
}

#featured-services h4 a {
    color: #fff
}

#featured-services h4 a:hover {
    color: #5ca595
}

#featured-services p {
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
    color: #fff;
    margin-bottom: 0
}

#about {
    background: url("../public/assets/img/about-bg.webp") center top no-repeat fixed;
    background-size: cover;
    padding: 60px 0 40px 0;
    position: relative
}

#about::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .92);
    z-index: 9
}

#about .container {
    position: relative;
    z-index: 10
}

#about .about-col {
    background: #fff;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, .08);
    margin-bottom: 20px
}

#about .about-col .img {
    position: relative
}

#about .about-col .img img {
    border-radius: 4px 4px 0 0
}

#about .about-col .icon {
    width: 64px;
    height: 64px;
    text-align: center;
    position: absolute;
    background-color: #15227e;
    border-radius: 50%;
    border: 4px solid #fff;
    left: calc(50% - 32px);
    bottom: -30px;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center
}

#about .about-col i {
    font-size: 28px;
    line-height: 0;
    color: #fff;
    transition: .3s
}

#about .about-col h2 {
    color: #000;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    padding: 0;
    margin: 40px 0 12px 0
}

.custom_title {
    margin: 10px 0 0 0 !important;
    float: left
}

#about .about-col h2 a {
    color: #15227e
}

@media only screen and (min-width:768px) {
    #about .about-col:hover .icon {
        background-color: #fff
    }

    #about .about-col:hover i {
        color: #5ca595
    }

    #about .about-col h2 a:hover {
        color: #5ca595
    }

    #services .box:hover .title a {
        color: #5ca595
    }
}

#about .about-col p {
    font-size: 16px;
    text-align: justify;
    line-height: 24px;
    color: #333;
    margin-bottom: 0;
    padding: 0 20px 20px 20px
}

#services {
    background: #fff;
    background-size: cover;
    padding: 60px 0 40px 0
}

#services .box {
    margin-bottom: 30px
}

@media only screen and (max-width:768px) {
    #services .icon {
        padding: 0 1rem !important
    }
}

#services .icon {
    float: left
}

#services .icon i {
    color: #15227e;
    font-size: 36px;
    line-height: 0;
    transition: .5s
}

#services .title {
    margin-left: 60px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 19px
}

#services .title a {
    color: #15227e
}

#services .description {
    font-size: 16px;
    text-align: justify;
    margin-left: 60px;
    padding-right: 10px;
    line-height: 24px;
    margin-bottom: 0
}

#call-to-action {
    background: linear-gradient(rgba(0, 142, 99, .1), rgba(0, 0, 0, .1)), url(../public/assets/img/call-to-action-bg.webp) fixed center center;
    background-size: cover;
    padding: 60px 0
}

@media only screen and (max-width:768px) {
    #call-to-action h3 {
        font-size: 32px !important
    }
}

#call-to-action h3 {
    color: #fff;
    font-size: 40px;
    font-weight: 600
}

#call-to-action p {
    font-size: 16px;
    text-align: justify;
    color: #fff
}

#call-to-action .cta-btn {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 25px;
    transition: .5s;
    margin-top: 10px;
    border: 2px solid #fff;
    color: #fff
}

#call-to-action .cta-btn:hover {
    background: #5ca595;
    border: 2px solid #5ca595
}

#skills .progress {
    height: 35px;
    margin-bottom: 10px;
    border-radius: 0
}

#skills .progress .skill {
    font-family: "Open Sans", sans-serif;
    line-height: 35px;
    padding: 0;
    margin: 0 0 0 20px;
    text-transform: uppercase
}

#skills .progress .skill .val {
    float: right;
    font-style: normal;
    margin: 0 20px 0 0
}

#skills .progress-bar {
    width: 1px;
    text-align: left;
    transition: .9s
}


#portfolio {
    padding: 9rem 0
}

@media screen and (max-width:768px) {
    #portfolio {
        padding: 8rem 0
    }
}

#portfolio #portfolio-flters {
    padding: 0;
    margin: 5px 0 35px 0;
    list-style: none;
    text-align: center
}

#portfolio #portfolio-flters li {
    cursor: pointer;
    margin: 15px 15px 15px 0;
    display: inline-block;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 20px;
    color: #666666;
    border-radius: 4px;
    text-transform: uppercase;
    background: #fff;
    margin-bottom: 5px;
    transition: all .3s ease-in-out
}

#portfolio #portfolio-flters li:hover,
#portfolio #portfolio-flters li.filter-active {
    background: #5ca595;
    color: #fff
}

#portfolio #portfolio-flters li:last-child {
    margin-right: 0
}

#portfolio .portfolio-wrap {
    box-shadow: 0 2px 12px rgba(0, 0, 0, .08);
    transition: .3s
}

#portfolio .portfolio-wrap:hover {
    box-shadow: 0 4px 14px rgba(0, 0, 0, .16)
}

#portfolio .portfolio-item {
    position: relative;
    height: 360px;
    overflow: hidden
}

#portfolio .portfolio-item figure {
    background: #000;
    overflow: hidden;
    height: 240px;
    position: relative;
    border-radius: 4px 4px 0 0;
    margin: 0
}

#portfolio .portfolio-item figure:hover img {
    opacity: .4;
    transition: .3s
}

#portfolio .portfolio-item figure .link-preview,
#portfolio .portfolio-item figure .link-details {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    line-height: 1;
    text-align: center;
    width: 36px;
    height: 36px;
    background: #fff;
    border-radius: 50%;
    transition: .2s linear
}

#portfolio .portfolio-item figure .link-preview i,
#portfolio .portfolio-item figure .link-details i {
    font-size: 22px;
    color: #333;
    line-height: 0
}

#portfolio .portfolio-item figure .link-preview:hover,
#portfolio .portfolio-item figure .link-details:hover {
    background: #5ca595
}

#portfolio .portfolio-item figure .link-preview:hover i,
#portfolio .portfolio-item figure .link-details:hover i {
    color: #fff
}

#portfolio .portfolio-item figure .link-preview {
    left: calc(50% - 38px);
    top: calc(50% - 18px)
}

#portfolio .portfolio-item figure .link-details {
    right: calc(50% - 38px);
    top: calc(50% - 18px)
}

#portfolio .portfolio-item figure:hover .link-preview {
    opacity: 1;
    left: calc(50% - 44px)
}

#portfolio .portfolio-item figure:hover .link-details {
    opacity: 1;
    right: calc(50% - 44px)
}

#portfolio .portfolio-item .portfolio-info {
    background: #fff;
    text-align: center;
    padding: 30px;
    height: 90px;
    border-radius: 0 0 3px 3px
}

#portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    line-height: 1px;
    font-weight: 700;
    margin-bottom: 18px;
    padding-bottom: 0
}

#portfolio .portfolio-item .portfolio-info h4 a {
    color: #333
}

#portfolio .portfolio-item .portfolio-info h4 a:hover {
    color: #5ca595
}

#portfolio .portfolio-item .portfolio-info p {
    padding: 0;
    margin: 0;
    color: #b8b8b8;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase
}

.portfolio-details {
    padding-top: 40px
}

.portfolio-details .portfolio-details-slider img {
    width: 100%
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #5ca595
}

.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #5ca595
}

.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0 0 30px rgba(51, 51, 51, .08)
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px
}

.portfolio-details .portfolio-info ul li+li {
    margin-top: 10px
}

.portfolio-details .portfolio-description {
    padding-top: 30px
}

.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px
}

.portfolio-details .portfolio-description p {
    padding: 0
}

#clients {
    padding: 60px 0
}

#clients img {
    opacity: .5;
    transition: .3s
}

#clients img:hover {
    opacity: 1
}

#clients .swiper-pagination {
    margin-top: 30px;
    position: relative
}

#clients .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #5ca595
}

#clients .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #5ca595
}

#team {
    background: #fff;
    padding: 60px 0 80px 0 !important
}

#team .member {
    text-align: center;
    /* margin-bottom: 20px; */
    /* background: rgba(239, 246, 247, .6); */
    position: relative
}

#team .member .member-info {
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transition: .2s
}

#team .member .member-info-content {
    margin-top: -50px;
    transition: margin .2s
}

#team .member:hover .member-info {
    background: rgba(0, 0, 0, .7);
    opacity: 1;
    transition: .4s
}

#team .member:hover .member-info-content {
    margin-top: 0;
    transition: margin .4s
}

#team .member h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
    color: #15227e
}

#team .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #15227e
}

#team .member .social {
    margin-top: 15px
}

#team .member .social a {
    padding-right:5px;
    transition: none;
    color: #15227e
}

#team .member .social a:hover {
    color: #5ca595
}

#team .member .social i {
    font-size: 18px;
    margin: 0 2px
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.blog {
    padding: 40px 0 20px 0
}

.blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1)
}

.blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden
}

.blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0
}

.blog .entry .entry-title a {
    color: #333333;
    transition: .3s
}

.blog .entry .entry-title a:hover {
    color: #5ca595
}

.blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #5ca595
}

.blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0
}

.blog .entry .entry-meta ul li+li {
    padding-left: 20px
}

.blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0
}

.blog .entry .entry-meta a {
    color: #666666;
    font-size: 14px;
    display: inline-block;
    line-height: 1
}

.blog .entry .entry-content p {
    line-height: 24px
}

.blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right
}

.blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #fff;
    color: #333333;
    padding: 6px 30px 8px 30px;
    transition: .3s;
    font-size: 14px;
    border-radius: 50px;
    border: 2px solid #5ca595
}

.blog .entry .entry-content .read-more a:hover {
    background: #5ca595;
    color: #fff
}

.blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold
}

.blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0
}

.blog .entry .entry-content blockquote p {
    color: #666666;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px
}

.blog .entry .entry-content blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #5ca595;
    margin-top: 20px;
    margin-bottom: 20px
}

.blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6
}

.blog .entry .entry-footer i {
    color: #5ca595;
    display: inline
}

.blog .entry .entry-footer a {
    color: #666666;
    transition: .3s
}

.blog .entry .entry-footer a:hover {
    color: #5ca595
}

.blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px
}

.blog .entry .entry-footer .cats li {
    display: inline-block
}

.blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px
}

.blog .entry .entry-footer .tags li {
    display: inline-block
}

.blog .entry .entry-footer .tags li+li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ","
}

.blog .entry .entry-footer .share {
    font-size: 16px
}

.blog .entry .entry-footer .share i {
    padding-left: 5px
}

.blog .entry-single {
    margin-bottom: 30px
}

.blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1)
}

.blog .blog-author img {
    width: 120px;
    margin-right: 20px
}

.blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0;
    padding: 0;
    color: #333333
}

.blog .blog-author .social-links {
    margin: 0 10px 10px 0
}

.blog .blog-author .social-links a {
    color: rgba(51, 51, 51, .4);
    margin-right: 5px;
    transition: .3s
}

.blog .blog-author .social-links a:hover {
    color: #5ca595
}

.blog .blog-author p {
    font-style: italic;
    color: #666666
}

.blog .blog-comments {
    margin-bottom: 30px
}

.blog .blog-comments .comments-count {
    font-weight: bold
}

.blog .blog-comments .comment {
    margin-top: 30px;
    position: relative
}

.blog .blog-comments .comment .comment-img {
    margin-right: 14px
}

.blog .blog-comments .comment .comment-img img {
    width: 60px
}

.blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px
}

.blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #666666;
    transition: .3s
}

.blog .blog-comments .comment h5 a:hover {
    color: #333333
}

.blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #5ca595
}

.blog .blog-comments .comment h5 .reply i {
    font-size: 20px
}

.blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #999999;
    margin-bottom: 5px
}

.blog .blog-comments .comment.comment-reply {
    padding-left: 40px
}

.blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1)
}

.blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px
}

.blog .blog-comments .reply-form p {
    font-size: 14px
}

.blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px
}

.blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #666666
}

.blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px
}

.blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #666666
}

.blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px
}

.blog .blog-comments .reply-form .btn-primary {
    border-radius: 50px;
    padding: 10px 30px;
    border: 0;
    background-color: #5ca595
}

.blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #1ee57a
}

.blog .blog-pagination {
    color: #5ca595
}

.blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none
}

.blog .blog-pagination li {
    margin: 0 5px;
    transition: .3s
}

.blog .blog-pagination li a {
    color: #5ca595;
    padding: 6px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-weight: bold
}

.blog .blog-pagination li.active,
.blog .blog-pagination li:hover {
    background: #5ca595;
    border-radius: 50px
}

.blog .blog-pagination li.active a,
.blog .blog-pagination li:hover a {
    color: #fff
}

.blog .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, .1)
}

.blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #333333;
    position: relative
}

.blog .sidebar .sidebar-item {
    margin-bottom: 30px
}

.blog .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
    border-radius: 50px
}

.blog .sidebar .search-form form input {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 70px);
    box-shadow: none
}

.blog .sidebar .search-form form input:focus {
    box-shadow: none
}

.blog .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 30px;
    margin: -1px;
    background: #5ca595;
    color: #fff;
    transition: .3s;
    line-height: 0;
    border-radius: 50px
}

.blog .sidebar .search-form form button i {
    line-height: 0
}

.blog .sidebar .search-form form button:hover {
    background: #13a456
}

.blog .sidebar .categories ul {
    list-style: none;
    padding: 0
}

.blog .sidebar .categories ul li+li {
    padding-top: 10px
}

.blog .sidebar .categories ul a {
    color: #333333;
    transition: .3s
}

.blog .sidebar .categories ul a:hover {
    color: #5ca595
}

.blog .sidebar .categories ul a span {
    padding-left: 5px;
    color: #b3b3b3;
    font-size: 14px
}

.blog .sidebar .recent-posts .post-item+.post-item {
    margin-top: 15px
}

.blog .sidebar .recent-posts img {
    width: 80px;
    float: left
}

.blog .sidebar .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold
}

.blog .sidebar .recent-posts h4 a {
    color: #333333;
    transition: .3s
}

.blog .sidebar .recent-posts h4 a:hover {
    color: #5ca595
}

.blog .sidebar .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #b3b3b3
}

.blog .sidebar .tags {
    margin-bottom: -10px
}

.blog .sidebar .tags ul {
    list-style: none;
    padding: 0
}

.blog .sidebar .tags ul li {
    display: inline-block
}

.blog .sidebar .tags ul a {
    color: #333333;
    font-size: 14px;
    padding: 6px 20px;
    margin: 0 6px 8px 0;
    border: 1px solid #5ca595;
    display: inline-block;
    border-radius: 50px;
    transition: .3s
}

.blog .sidebar .tags ul a:hover {
    color: #fff;
    background: #5ca595
}

.blog .sidebar .tags ul a span {
    padding-left: 5px;
    color: white;
    font-size: 14px
}

@media only screen and (min-width:768px) {
    .hover-item {
        transition: .3s
    }

    .hover-item:hover {
        transform: translate(0, -10px)
    }
}

.offcanvas-backdrop.show {
    opacity: 0 !important
}

.dropdown-item:active {
    background-color: rgba(203, 201, 201, .4) !important
}

.hero {
    min-height: 45vh
}

.hero_bg::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);
    z-index: -1
}

.bg_img1 {
    background-image: url('../public/assets/images/Products-all-img/chemicals/bg_img/chemicals_bg.webp')
}

.bg_img2 {
    background-image: url('../public/assets/images/About/aboutus-bg.webp')
}

.bg_img3 {
    background-image: url('../public/assets/images/Services/service_bg.webp')
}

.bg_img4 {
    background-image: url('../public/assets/images/Contact-Us/contact_bg.webp')
}

.bg_img5 {
    background-image: url('../public/assets/images/Products-all-img/bg-images/agro-bg.webp')
}

.bg_img6 {
    background-image: url('https://img.freepik.com/premium-photo/concept-pattern-palm-oil-bottle-supermarket_109549-2077.jpg?w=1060')
}

.bg_img7 {
    background-image: url('../public/assets/images/Products-all-img/bg-images/petroleum-bg.webp')
}

.bg_img8 {
    background-image: url('../public/assets/images/Products-all-img/bg-images/cattlefeed-bg.webp')
}

.bg_img9 {
    background-image: url('../public/assets/images/Products-all-img/bg-images/biofuel-bg.webp')
}

.bg_img10 {
    background-image: url('../public/assets/images/Home-page-img/hero-section/smooth-pebble-stack-wet.webp')
}

.bg_img11 {
    background-image: url('../public/assets/images/Products-all-img/bg-images/metals-bg.webp')
}

.bg_img12 {
    background-image: url('../public/assets/images/Home-page-img/hero-section/group-steel-rod.webp')
}

.hero_bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 45vh;
    position: relative;
    z-index: 1
}

.breadcrums {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 10rem
}

.breadcrums_title {
    font-size: 6rem;
    color: white
}

.breadcrums_subtitle {
    display: flex;
    justify-content: space-around;
    font-size: 2rem;
    padding-left: 0 !important
}

.breadcrums_items {
    padding-left: 2rem;
    color: white
}

.icons_section {
    padding: 10rem 0 7rem 0
}

@media only screen and (min-width:320px) and (max-width:768px) {
    .sm_flex_col {
        flex-direction: column;
        text-align: center
    }

    .icons_section {
        padding: 5rem 0 2rem 0
    }

    .sm_pd_top {
        padding-top: 2rem
    }
}

.about .content h3 {
    font-weight: 700;
    font-size: 32px;
    font-family: var(--font-secondary);
    color: #001f8d
}

.about .content ul {
    list-style: none;
    padding: 0
}

.about .content ul li {
    display: flex;
    align-items: flex-start;
    margin-top: 40px
}

.about .content ul i {
    flex-shrink: 0;
    font-size: 48px;
    color: #15227e;
    margin-right: 20px;
    line-height: 0
}

.about .content ul h5 {
    font-size: 18px;
    font-weight: 700;
    color: #19335c
}

.about .content ul p {
    font-size: 15px
}

.about .content p:last-child {
    margin-bottom: 0
}

.about_content {
    font-size: 18px
}

.stats-counter .stats-item {
    padding: 30px;
    width: 100%
}

.purecounter div::after {
    content: "+" !important
}

.stats-counter .stats-item span {
    font-size: 48px;
    display: block;
    color: #15227e;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative
}

.stats-counter .stats-item span:after {
    content: "";
    position: absolute;
    display: block;
    width: 35px;
    height: 3px;
    background: #5ca595;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto
}

.stats-counter .stats-item p {
    padding: 0;
    margin: 0;
    font-family: #15227e;
    font-size: 16px;
    font-weight: 500;
    color: #15227e
}

.team .member {
    text-align: center;
    /* margin-bottom: 20px; */
    background: #fff;
    /* border: 1px solid rgba(14, 29, 52, .15) */
}

.team .member img {
    margin: -1px -1px 30px -1px
}

.team .member .member-content {
    padding: 0 20px 30px 20px
}

.team .member h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px
}

.team .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #6c757d
}

.team .member p {
    padding-top: 10px;
    font-size: 14px;
    font-style: italic;
    color: #6c757d
}

.team .member .social {
    margin-top: 15px
}

.team .member .social a {
    color: rgba(14, 29, 52, .5);
    transition: .3s
}

.team .member .social a:hover {
    color: var(--color-primary)
}

.team .member .social i {
    font-size: 18px;
    margin: 0 2px
}

.input_box {
    height: 45vh;
    background-color: #eff6f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.input-container {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 0 5% 0%;
    font-size: 16px
}

@media only screen and (min-width:320px) and (max-width:768px) {
    .input-container {
        padding: 0 0% 0% 0%
    }
}

.search_input {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: none;
    outline: none;
    padding: 18px 16px;
    background-color: transparent;
    cursor: pointer;
    transition: all .5s ease-in-out
}

.search_input::placeholder {
    color: transparent
}

.search_input:focus::placeholder {
    color: rgb(131, 128, 128)
}

.search_input:focus,
.search_input:not(:placeholder-shown) {
    background-color: #fff;
    border: 2px solid #15227e;
    width: 290px;
    cursor: none;
    padding: 18px 16px 18px 45px
}

.search_icon {
    position: absolute;
    left: 0;
    height: 45px;
    width: 45px;
    background-color: #fff;
    border-radius: 99px;
    z-index: -1;
    fill: #15227e;
    border: 1px solid #15227e
}

.search_input:focus+.search_icon,
.search_input:not(:placeholder-shown)+.search_icon {
    z-index: 0;
    background-color: transparent;
    border: none
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none
}

:root {
    --primary: #15227e;
    --white: #ffffff;
    --bg: #f5f5f5
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth
}

@media (min-width:1440px) {
    html {
        zoom: 1
    }
}

@media (min-width:2560px) {
    html {
        zoom: 1.2
    }
}

@media (min-width:3860px) {
    html {
        zoom: 1.5
    }
}

::-webkit-scrollbar {
    width: 1.3rem
}

::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: #797979;
    transition: all .5s ease-in-out
}

::-webkit-scrollbar-thumb:hover {
    background: #222224
}

::-webkit-scrollbar-track {
    background: #f9f9f9
}

.container_for_swiper {
    max-width: 124rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 10rem;
    padding-bottom: 6rem;
    margin: 0 auto
}

.heading {
    padding: 1rem 0;
    font-size: 3.5rem;
    text-align: center
}

.swiper_container {
    transform: translate3d(0, 0, 0);
    transition-timing-function: ease-in-out;
    transition-duration: .5s;
    height: 52rem;
    padding: 1.8rem 0;
    position: relative
}

.swiper_container_slide {
    width: 37rem !important;
    height: 42rem !important;
    position: relative !important
}

@media (max-width:500px) {
    .swiper_container {
        height: 47rem
    }

    .swiper_container_slide {
        width: 28rem !important;
        height: 36rem !important
    }

    .swiper_container_slide img {
        width: 28rem !important;
        height: 36rem !important
    }
}

.swiper_container_slide img {
    width: 37rem;
    height: 42rem;
    border-radius: 2rem;
    object-fit: cover
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none
}

.slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center
}

.slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important
}

@media (max-width:990px) {
    .slider-controler .swiper-button-next {
        left: 70% !important;
        transform: translateX(-70%) !important
    }
}

@media (max-width:450px) {
    .slider-controler .swiper-button-next {
        left: 80% !important;
        transform: translateX(-80%) !important
    }
}

@media (max-width:990px) {
    .slider-controler .swiper-button-prev {
        left: 30% !important;
        transform: translateX(-30%) !important
    }
}

@media (max-width:450px) {
    .slider-controler .swiper-button-prev {
        left: 20% !important;
        transform: translateX(-20%) !important
    }
}

.slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0 8px 24px rgba(18, 28, 53, .1))
}

.slider-controler .slider-arrow .bi {
    font-size: 2.8rem;
    color: #222224;
    position: absolute
}

.bi-arrow-left-circle-fill {
    animation: moveHorizontalLeft .9s ease-in-out infinite alternate
}

.bi-arrow-right-circle-fill {
    animation: moveHorizontalRight .9s ease-in-out infinite alternate
}

@keyframes moveHorizontalLeft {
    0% {
        left: 0
    }

    100% {
        left: calc(50% - 20px)
    }
}

@keyframes moveHorizontalRight {
    0% {
        right: 0
    }

    100% {
        right: calc(50% - 20px)
    }
}

.slider-controler .slider-arrow::after {
    content: ''
}

.swiper-pagination {
    position: relative !important;
    width: 15rem !important;
    bottom: .5rem !important
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0 8px 24px rgba(18, 28, 53, .1))
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: #222224
}

#agro_commodities {
    padding: 60px 0
}

@media only screen and (min-width:320px) and (max-width:768px) {
    .breadcrums_title {
        font-size: 40px;
        text-align: center
    }
}

.timeline {
    position: relative;
    margin: auto
}

.product_card {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5rem;
    position: relative;
    animation: movedown 1s linear forwards;
    opacity: 0
}

.product_card:nth-child(1) {
    animation-delay: 0s
}

.product_card:nth-child(2) {
    animation-delay: 1s
}

.product_card:nth-child(3) {
    animation-delay: 2s
}

.product_card:nth-child(4) {
    animation-delay: 3s
}

.product_card:nth-child(5) {
    animation-delay: 4s
}

.product_card:nth-child(6) {
    animation-delay: 5s;
    padding-bottom: 0
}

@keyframes movedown {
    0% {
        opacity: 1;
        transform: translateY(-30px)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

.text_box {
    padding: 0 20px;
    background-color: #fff;
    position: relative;
    border-radius: 6px;
    font-size: 18px
}

.text_card {
    font-size: 32px;
    font-weight: 500;
    color: #15227e
}

.text_box h2 {
    font-weight: 600
}

.product_desc {
    font-size: 18px;
    text-align: justify
}

.ul_list {
    padding: 1px
}

.ul_list li {
    list-style: none;
    font-size: 18px;
    padding: 7px 0
}

.ul_list li i {
    color: #15227e;
    font-size: 22px
}

.right_container {
    padding-left: 4.1rem !important
}

.animated_icon_div {
    position: absolute;
    width: 80px;
    height: 80px;
    border: 1px solid black;
    background: white;
    border-radius: 50%;
    right: -7.5%;
    top: 40%;
    z-index: 10
}

@media only screen and (min-width:769px) and (max-width:1199px) {
    .animated_icon_div {
        right: -10.5%
    }
}

@media only screen and (min-width:1200px) and (max-width:1400px) {
    .animated_icon_div {
        right: -9%
    }
}

@media only screen and (min-width:1400px) and (max-width:3000px) {
    .animated_icon_div {
        right: -7.5%
    }
}

@media only screen and (min-width:1280px) {
    .right_container {
        padding-left: 4.2rem !important
    }
}

.container_part {
    position: relative;
    z-index: -1
}

.container_part .animated_icon {
    position: relative;
    width: 50px;
    right: -15px;
    top: 15px;
    z-index: 10
}

@media only screen and (min-width:320px) and (max-width:768px) {
    .animated_icon_div {
        position: absolute;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: white;
        left: -7px;
        top: 13%;
        z-index: 10
    }

    .animated_icon_div:nth-child(2) {
        top: 125%
    }

    .animated_icon_div:nth-child(4) {
        top: 125%
    }

    .animated_icon_div:nth-child(6) {
        top: 125%
    }

    .timeline::after {
        animation: moveline 25s linear forwards !important
    }

    .container_part .animated_icon {
        position: relative;
        width: 42px;
        left: 7px;
        top: 7px;
        z-index: 10
    }

    .product_container {
        justify-content: end
    }

    .left_container {
        padding-left: 3.5rem !important
    }

    .timeline {
        margin: 50px auto
    }

    .timeline::after {
        left: 31px !important
    }

    .container_part {
        width: 100%;
        padding-left: 80px;
        padding-right: 25px
    }

    .product_desc {
        font-size: 17px
    }

    .text_box {
        font-size: 16px;
        padding-top: 2rem
    }

    .text_box small {
        margin-bottom: 10px
    }

    .right_container {
        left: 0;
        z-index: -2
    }

    .left_container img,
    .right_container img {
        left: 10px
    }
}

.timeline::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 50%;
    background: #15227e;
    top: 0;
    left: 50%;
    margin-left: -3px;
    z-index: -1;
    animation: moveline 12s linear forwards
}

@keyframes moveline {
    0% {
        height: 0
    }

    100% {
        height: 100%
    }
}

.back-to-top {
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 996;
    background: #15227e;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all .5s;
    cursor: pointer
}

.back-to-top i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #fff;
    line-height: 0;
    animation: gototop 1s linear infinite alternate-reverse
}

.back-to-top:hover {
    background: #5ca595;
    color: #fff
}

.back-to-top.active {
    visibility: visible;
    opacity: 1
}

@keyframes gototop {
    0% {
        transform: translateY(-.1rem)
    }

    100% {
        transform: translateY(.7rem)
    }
}

.whatsapp_float {
    color: white !important;
    position: fixed;
    width: 55px;
    height: 55px;
    bottom: 20px;
    left: 30px;
    background-color: #0e9e15;
    border-radius: 50%;
    text-align: center;
    font-size: 35px;
    z-index: 999;
    animation: 2s infinite pulse-animation-whatsapp
}

@media only screen and (max-width:600px) {
    .whatsapp_float {
        bottom: 15px;
        left: 15px
    }
}

@keyframes pulse-animation-whatsapp {
    0% {
        box-shadow: 0 0 0 0 #30be3a
    }

    100% {
        box-shadow: 0 0 0 14px transparent
    }
}